import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import axios from 'axios';
import { currency } from 'libs/framework';
import { getStoredAccessToken } from 'libs/restful';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Keyboard from 'react-simple-keyboard';
import KeyboardReact from 'react-simple-keyboard';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import {
    BookingItem,
    invoiceItem,
    listBooking,
    Staff,
    UseParams
} from '@/_shared';
import { keyboardLayoutNumberOnly } from '@/_shared/layouts';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const _InvoiceItemForm = styled.div`
  .wraper-discount {
    display: flex;

    .discountValue {
      flex : 1;
    }

    .discount-amount,.discount-unit {

      &:focus {
        border-color: none;
        outline: 0;
        box-shadow:none;
      }
      
      height: 10vh;
      border: none;
      border-radius: 10px 0 0 10px;
      border: 1px solid rgb(239,239,239);
      font-weight: 700;
      font-size: 3.5vh;
    }
    
    .discount-unit {
      border: none;
      
      .ant-select-selector {
        height:calc( 10vh - 2px);
        border: none;
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        margin-top: 1px;
      }
      
    }
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
        box-shadow : none;
      }

    .discount-unit-form-item {
      width: 100px;
    }
    
  }

.wraper-staff {
    height: 33vh;
    overflow-y: scroll;
    padding-bottom: 0 !important;
    margin: 1vh 0;

  .add-button {
    font-size: 2vh;
    height: 5vh;
    font-weight: 700;
  }

  .manual-commission-number {
    height: 5vh;
    margin-top: 1px;
    border: 0;
    border-radius: 0;
    font-weight: 700;
    font-size: 2vh;
  }

  .manual-commission-unit {
    .ant-select-selector {
      display: flex;
      align-items: center;
      height: 5vh;
      border: 0;
      border-radius: 0px 10px 10px 0;
      margin-top: 1px;
      font-weight: 700;
      font-size: 2vh;
    }
  }

  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    margin-top: 5px;

    span {
      font-size: 20px;
    }
  }
}
  
  .staff-slected {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: auto;
    }

    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
        box-shadow : none;
    }
    
    .ant-select-selector {
      height: 5vh ;
      border: none;
      border-radius:10px 0px 0px 10px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      font-weight: 700;
      font-size: 2vh;
      margin-right : 0px;
    }
    .ant-select-multiple .ant-select-selection-item {
      height: 4vh;
      display: flex;
      align-items: center;
      line-height: normal;
      padding: 0;
    }
  }
  .wraper-form-button {
    display : flex;
    justify-content : space-around;
    
    .button {
      width: 9vw;
      height: 7vh;
      border: 0;
      font-size: 1.5rem;
      border-radius: 10px;
      font-weight: 600;
      outline: 0;
      background: #00bedf;
      color: #ffffff;
      cursor: pointer;
    }

  }

  .btnKeyboard {
    height: 20vh;
    font-size: 6vh;
    font-weight: 700;
  }

  .wraper-infomation {
    margin-top : 3vh;
    
    p {
      display: flex;
      justify-content:space-between;
      font-size: 2.5vh;
      font-weight: 700;
    }

    .into-price {
      border-top : 1px solid #333333;
      padding-top : 2vh ;
      font-size : 3.5vh;
    }
    
  }
`;

interface IInvoiceItemFormProps {
}

interface NewBookingItem extends BookingItem {
  discountUnit: string,
  discount: number
}

export const InvoiceItemForm = (props: React.PropsWithChildren<IInvoiceItemFormProps>) => {
  const { } = props;

  const [targetInput, setTargetInput] = React.useState(
    {
      position: 'discount',
      index: 0
    } as {
      position: string,
      index: number
    }
  );

  const [staffs, setStaffs] = React.useState<Staff[]>();

  const [form] = Form.useForm();

  const keyboard = React.useRef<KeyboardReact>();

  const history = useHistory();

  const [invoiceItemState] = useRecoilState(invoiceItem);

  const [listBookingState, setListBookingState] = useRecoilState(listBooking);

  const [data, setData] = React.useState({
    discountUnit: 'MONEY',
    discount: 0,
    ...invoiceItemState
  } as NewBookingItem);

  React.useEffect(
    () => {
      axios
        .get(`${API_URL}/Staffs?order=id&orderType=DESC`, {
          headers: {
            authorization: `Bearer ${getStoredAccessToken()}`
          }
        })
        .then(e => {
          setStaffs(e.data.data as Staff[]);
        });

      if (invoiceItemState?.discountValue) {
        keyboard.current?.setInput(String(invoiceItemState?.discountValue));
      }
    },
    []
  );

  const onFieldsChange = () => {
    const formValue = form.getFieldsValue() as typeof data;

    const posInvoiceCommissions = [] as typeof data.posInvoiceCommissions;

    formValue.posInvoiceCommissions?.forEach((e) => {
      posInvoiceCommissions?.push(e);
    });

    setData({
      ...invoiceItemState,
      ...data,
      ...formValue,
      posInvoiceCommissions: posInvoiceCommissions
    });
  };

  const { index } = useParams<UseParams>();

  const onSubmit = () => {

    const newListBookingState = [] as BookingItem[];
    const indexFormat = Number(index);

    listBookingState.map((e, index) => {
      if (indexFormat == index) {
        return newListBookingState.push({
          ...e,
          ...data,
          price: data.discountUnit === 'PERCENT' ? (data.rootPrice / 100 * (100 - data.discount)) : (data.rootPrice - data.discount)
        });
      }

      return newListBookingState.push({
        ...e
      });
    });

    setListBookingState(newListBookingState);
    return history.push('/casher');
  };

  const cssOptionSelect = {
    height: '10vh',
    fontSize: '2.5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 !important'
  };

  return (
    <_InvoiceItemForm>
      <Row gutter={50}>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          span={12}
        >
          <Form
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            form={form}
            className='form'
            initialValues={
              {
                discountUnit: invoiceItemState?.discountUnit ? invoiceItemState.discountUnit : 'MONEY',
                discountValue: invoiceItemState?.discountValue && invoiceItemState.discountValue,
                posInvoiceCommissions: invoiceItemState?.posInvoiceCommissions
              }
            }
          >
            <div className='wraper-discount'>
              <Form.Item className='discountValue' name='discountValue'>
                <Input
                  type='number'
                  onChange={
                    () => {
                      keyboard.current?.setInput(form.getFieldValue('discountValue'));
                    }
                  }
                  onFocus={
                    () => {
                      keyboard.current?.setInput(form.getFieldValue('discountValue'));
                      setTargetInput({
                        position: 'discount',
                        index: 0
                      });
                    }}
                  className='discount-amount'
                  placeholder="Giảm giá"
                />
              </Form.Item>
              <Form.Item className='discount-unit-form-item' name='discountUnit'>
                <Select className='discount-unit' >
                  <Select.Option
                    style={cssOptionSelect}
                    value="PERCENT"
                  >
                    %
                  </Select.Option>
                  <Select.Option
                    style={cssOptionSelect}
                    value="MONEY"
                  >
                    đ
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className='wraper-staff'>
              <Form.List name="posInvoiceCommissions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col span={10}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'staffId']}
                            rules={[{ required: true, message: 'Missing last name' }]}
                            className='staff-slected'
                          >
                            <Select
                              placeholder="Chọn nhân viên"
                            >
                              {staffs?.map(e => {
                                return (
                                  <Select.Option
                                    style={{
                                      height: '10vh',
                                      fontSize: '2.5vh',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      padding: '0 !important'
                                    }}
                                    key={e.id}
                                    value={e.id}
                                  >
                                    {e.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'manualCommissionValue']}
                            rules={[{ required: true, message: 'Missing last name' }]}
                          >
                            <Input
                              className='manual-commission-number'
                              type='number'
                              onFocus={(e) => {
                                keyboard.current?.setInput(e.target.value);
                                setTargetInput({
                                  position: 'commission',
                                  index: index
                                });
                              }}
                              placeholder="Hoa hồng"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'manualCommissionUnit']}
                            rules={[{ required: true, message: 'Missing last name' }]}
                          >
                            <Select
                              className='manual-commission-unit'
                            >
                              <Select.Option
                                style={cssOptionSelect}
                                value="PERCENT"
                              >
                                %
                          </Select.Option>
                              <Select.Option
                                style={cssOptionSelect}
                                value="MONEY"
                              >
                                đ
                          </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col className='delete-button' span={4}>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        className='add-button'
                        onClick={
                          () => add({
                            manualCommissionUnit: 'MONEY'
                          })
                        }
                        icon={<PlusOutlined />}
                        block
                      >
                        Thêm nhân viên
                    </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <div className='wraper-form-button'>
              <Button
                className='button'
                htmlType='submit'
              >
                OK
              </Button>
              <Button
                onClick={
                  () => {
                    history.push('/casher');
                  }
                }
                className='button'
              >
                Huỷ bỏ
              </Button>
            </div>
            <div className='wraper-infomation'>
              <p className='name-service'>
                {invoiceItemState?.name}
              </p>
              <p>
                Giá gốc:<span>{currency(invoiceItemState?.rootPrice)}</span>
              </p>
              <p className='discount-price'>
                Giảm giá:
                <span>
                  {
                    currency(data.discountUnit === 'PERCENT'
                      ? (data.price / 100 * data.discount)
                      : (data.discount))
                  }
                </span>
              </p>
              <p className='into-price'>
                Thành tiền:
                <span>
                  {
                    currency(data.discountUnit === 'PERCENT'
                      ? (data.rootPrice / 100 * (100 - data.discount))
                      : (data.rootPrice - data.discount))
                  }
                </span>
              </p>
            </div>
          </Form>
        </Col>
        <Col span={12}>
          <Keyboard
            {...keyboardLayoutNumberOnly}
            onChange={(e) => {
              if (targetInput.position === 'discount') {
                form.setFieldsValue({
                  discountValue: e
                });
              } else {
                const newFormValue = form.getFieldsValue();
                newFormValue.posInvoiceCommissions.forEach((element, index) => {
                  if (targetInput.index === index) {
                    element.manualCommissionValue = e;
                  }
                });

                form.setFieldsValue({
                  ...newFormValue
                });
              }
              setData({
                ...data,
                discount: Number(form.getFieldValue('discountValue'))
              });
            }}
            keyboardRef={r => (keyboard.current = r)}
            onRender={() => {
              return;
            }}
          />
        </Col>
      </Row>
    </_InvoiceItemForm>
  );
};
