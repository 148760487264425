import React from 'react';
import styled from 'styled-components';

import { InvoiceDiscountForm } from './InvoiceDiscountForm';

interface IInvoiceItemProps {

}
const _InvoiceDiscount = styled.div`
  padding-top: 10vh;
  padding-right: 20vw;
  padding-left: 20vw;
`;

export const InvoiceDiscount = (props: React.PropsWithChildren<IInvoiceItemProps>) => {
  const { } = props;

  React.useEffect(() => {
    //** effect here */
  });

  return (
    <_InvoiceDiscount>
      <InvoiceDiscountForm />
    </_InvoiceDiscount>
  );
};