import 'react-simple-keyboard/build/css/index.css';

import React, { FunctionComponent, MutableRefObject } from 'react';
import Keyboard from 'react-simple-keyboard';

interface IKeyboard {
  layoutName?: string;
  phoneorcode: string;
  name: string;
  inputName: string;
}
interface IProps {
  inputName?: string;
  commonOptions?: any;
  onKeyPress: (input: string, keyboardRef: MutableRefObject<Keyboard | undefined>) => void;
  onChange?: (input: string) => void;
  onChangeAll?: (input: IKeyboard) => void;
  keyboardRef: MutableRefObject<Keyboard | undefined>;
}
export const KeyboardWrapper: FunctionComponent<IProps> = ({ inputName, commonOptions, onKeyPress, onChange, onChangeAll, keyboardRef }: IProps) => {

  return (
    <Keyboard
      inputName={inputName}
      keyboardRef={r => (keyboardRef.current = r)}
      {...commonOptions}
      onChange={onChange}
      onKeyPress={e => onKeyPress(e, keyboardRef)}
      onRender={() => { return; }}
      onChangeAll={onChangeAll}
    />
  );
};