import React from 'react';
import styled from 'styled-components';

const _DefaultLayout = styled.div`
  
`;

interface IDefaultLayoutProps {

}

export const DefaultLayout = (props: React.PropsWithChildren<IDefaultLayoutProps>) => {
  const { } = props;

  return (
    <_DefaultLayout>
      {props.children}
    </_DefaultLayout >
  );
};