import { Button, Col, Form, Input, Row, Select } from 'antd';
import { currency } from 'libs/framework';
import React from 'react';
import { useHistory } from 'react-router';
import Keyboard from 'react-simple-keyboard';
import KeyboardReact from 'react-simple-keyboard';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { discountInvoice, InvoiceDiscount, listBooking } from '@/_shared';
import { keyboardLayoutNumberOnly } from '@/_shared/layouts';

const _InvoiceItemForm = styled.div`
  .wraper-discount {
    display: flex;

    .discountValue {
      flex : 1;
    }

    .discount-amount,.discount-unit {
      height: 10vh;
      border: none;
      border-radius: 10px 0 0 10px;
      border: 1px solid rgb(239,239,239);
      font-weight: 700;
      font-size: 3.5vh;

      &:focus {
        border-color: none;
        outline: 0;
        box-shadow:none;
      }
    }
    .discount-unit {
      border: none;

      .ant-select-selector {
        height:calc( 10vh - 2px);
        border: none;
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        margin-top: 1px;
      }
    }

    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
        box-shadow : none;
      }

    .discount-unit-form-item {
      width: 100px;
    }
  }
 
  .wraper-form-button {
    display : flex;
    justify-content : space-around;
    
    .button {
      width: 9vw;
      height: 7vh;
      border: 0;
      font-size: 1.5rem;
      border-radius: 10px;
      font-weight: 600;
      outline: 0;
      background: #00bedf;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .btnKeyboard {
    height: 20vh;
    font-size: 6vh;
    font-weight: 700;
  }
  
  .wraper-infomation {
    margin-top : 3vh;
    p {
      display: flex;
      justify-content:space-between;
      font-size: 2.5vh;
      font-weight: 700;
    }

    .into-price {
      border-top : 1px solid #333333;
      padding-top : 2vh ;
      font-size : 3.5vh;
    }
  }
`;

interface IInvoiceItemFormProps {
}

interface NewInvoiceDiscout extends InvoiceDiscount {
  price: number
}

export const InvoiceDiscountForm = (props: React.PropsWithChildren<IInvoiceItemFormProps>) => {
  const { } = props;

  const [form] = Form.useForm();

  const keyboard = React.useRef<KeyboardReact>();

  const history = useHistory();

  const [discountInvoiceState, setDiscountInvoiceState] = useRecoilState(discountInvoice);

  const [listBookingState] = useRecoilState(listBooking);

  const [data, setData] = React.useState<NewInvoiceDiscout>();

  React.useEffect(() => {
    const newdata = {} as NewInvoiceDiscout;
    newdata.price = 0;

    listBookingState.forEach((e) => {
      newdata.price = newdata.price + e.price;
    });

    if (!discountInvoiceState) {
      setData({
        ...newdata,
        discountUnit: 'MONEY',
      });
    } else {
      setData({
        ...newdata,
        discountUnit: discountInvoiceState.discountUnit,
        discountValue: discountInvoiceState.discountValue
      });

      keyboard.current?.setInput(String(discountInvoiceState?.discountValue));
    }
  },
    []
  );

  const onFieldsChange = () => {
    const dataForm = form.getFieldsValue();

    setData({
      ...data,
      discountUnit: dataForm.discountUnit ? dataForm.discountUnit : 'MONEY',
      discountValue: Number(dataForm.discountValue),
    } as NewInvoiceDiscout);

  };

  const onSubmit = () => {

    setDiscountInvoiceState({ ...data } as InvoiceDiscount);

    history.push('/casher');
  };

  const cssOptionSelect = {
    height: '10vh',
    fontSize: '2.5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 !important'
  };

  return (
    <_InvoiceItemForm>
      <Row gutter={50}>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          span={12}
        >
          <Form
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            form={form}
            className='form'
            initialValues={{
              discountUnit: discountInvoiceState?.discountUnit
                ? discountInvoiceState?.discountUnit
                : 'MONEY',
              discountValue: discountInvoiceState?.discountValue
            }}
          >
            <div className='wraper-discount'>
              <Form.Item className='discountValue' name='discountValue'>
                <Input
                  type='number'
                  onChange={
                    () => {
                      keyboard.current?.setInput(form.getFieldValue('discountValue'));
                    }
                  }
                  onFocus={
                    () => {
                      keyboard.current?.setInput(form.getFieldValue('discountValue'));
                    }}
                  className='discount-amount'
                  placeholder="Giảm giá"
                />
              </Form.Item>
              <Form.Item className='discount-unit-form-item' name='discountUnit'>
                <Select className='discount-unit' >
                  <Select.Option
                    style={cssOptionSelect}
                    value="PERCENT"
                  >
                    %
                  </Select.Option>
                  <Select.Option
                    style={cssOptionSelect}
                    value="MONEY"
                  >
                    đ
                </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className='wraper-form-button'>
              <Button className='button' htmlType='submit'>
                OK
              </Button>
              <Button onClick={() => {
                history.push('/casher');
              }} className='button' htmlType='submit'>
                Huỷ bỏ
              </Button>
            </div>
            <div className='wraper-infomation'>
              <p>
                Giá gốc:<span>{currency(data?.price)}</span>
              </p>
              <p className='discount-price'>
                Giảm giá:
                <span>
                  {
                    currency(
                      data?.discountUnit === 'PERCENT'
                        ? (data.price / 100 * data.discountValue)
                        : (data?.discountValue)
                    )
                  }
                </span>
              </p>
              <p className='into-price'>
                Thành tiền:
                <span>
                  {
                    currency(
                      data?.discountUnit === 'PERCENT'
                        ? (data.price / 100 * (100 - data.discountValue))
                        : (data ? data.price - data.discountValue : 0)
                    )
                  }
                </span>
              </p>
            </div>
          </Form>
        </Col>
        <Col span={12}>
          <Keyboard
            {...keyboardLayoutNumberOnly}
            onChange={(e) => {
              form.setFieldsValue({
                discountValue: e
              });
              setData(
                {
                  ...data,
                  discountValue: Number(form.getFieldValue('discountValue'))
                } as NewInvoiceDiscout
              );
            }}
            keyboardRef={r => (keyboard.current = r)}
            onRender={
              () => {
                return;
              }
            }
          />
        </Col>
      </Row>
    </_InvoiceItemForm >
  );
};
