
export const keyboardLayoutNumberOnly = {
  layout: {
    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '0 {bksp}'
    ]
  },
  buttonTheme: [{
    class: 'btnKeyboard',
    buttons: '1 2 3 4 5 6 7 8 9 0 - + = {bksp} q w e r t y u i o p {clear} a s d f g h j k l : {enter} z x c v b n m , . ? {shift} .com @ {space} |'
  }],
  theme: 'hg-theme-default hg-theme-numeric',
  mergeDisplay: true,
  display: {
    '{bksp}': 'Xóa'
  },
  syncInstanceInputs: true
};