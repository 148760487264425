import 'react-simple-keyboard/build/css/index.css';

import { Alert, Button } from 'antd';
import axios from 'axios';
import { getStoredAccessToken } from 'libs/restful';
import React, { MutableRefObject } from 'react';
import { useHistory } from 'react-router';
import Keyboard from 'react-simple-keyboard';
import KeyboardReact from 'react-simple-keyboard';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import {
    currentCustomerState,
    currentUserState,
    ICustomer,
    VietKey
} from '@/_shared';
import { ArrowRightOutlined } from '@ant-design/icons';

import { KeyboardWrapper } from './Keyboard';
import { useVisible } from './Visible';

const _CustomerSearch = styled.div`
  .container{
    background: white;
    .Keyboard{
      width: 98%;
      margin:auto;
      position: fixed;
      background:#f0f2f5;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center; 
      padding-right: 10px;
      padding-left:10px;
      padding-bottom: 20px;
    }
    .simple-keyboard {
      width: 100%;
      margin: auto;
      z-index:10000000;
      .btnKeyboard{
        font-size: 20px;
        height: 9.3vh;
        font-weight: 600;
        font-size: 4vh;
      }
    }
  }
  .cover  {
    width: 65vw;
    margin: auto;
    background: white;
    text-align: center;
    align-items: center;
    padding-top: 5vh;
  }

  .flex-btn-create,.flex-btn-ignore{
    margin: auto;
    display: flex;
    z-index: 10;
    position: relative;
    width: 25%;
    border-radius: 25px;
    margin-top: 2vh;
    .btn-Create, .btn-ignore{
      height: 7vh;
      flex-basis: 230px;
      border: 0;
      font-size: 1.5rem;
      border-radius: 0;
      font-weight:600;
      outline: 0;
      -webkit-appearance: none;
      background: #00bedf;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .flex-form{
    width: 100%;
    position: relative;
    margin-bottom: 2vh;
    .btn-comeBack ,.btn-name{
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      background: #00bedf;
      color: #ffffff;
      height: 10vh;
    }
    .btn-phone ,.btn-name-right{
      height: 10vh;
      right: 0;
      top: 0;
      position: absolute;
      background: #00bedf;
      color: #ffffff;
    }   
    input {
      width: 100%;
      height: 10vh;
      border-radius: 13px;
      padding: 10px 80px;
      border: 1px solid rgb(239, 239, 239);
      font-weight : 700;
      font-size: 4.5vh;
    }
    input::placeholder {
      color:rgb(202 184 184);
      opacity: 0.8;
      text-transform: uppercase;
    }
    .searchs{
      position: absolute;
      width: 86%;
      margin-left: 72px;
      z-index: 1000000;
      overflow-y: scroll;
      height:21vh;
      max-height: 21vh;
      background:#f3f3f3;
      .search{
          width:100%;
          margin: auto;
          list-style: none;
          text-align: initial;
          padding-left: 7px;
          span{
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 600;
          }
      }
      .search:hover{
        background: #00bedf;
      }
    }
  }

  .flex-form > * {
    border: 0;
    line-height: 50px;
    font-size: 1rem;
    border-radius: 0;
    outline: 0;
    -webkit-appearance: none;
    cursor: pointer;
  }
`;

interface ICustomerSearchProps {

}
interface IKeyboard {
  layoutName?: string;
  phoneorcode: string;
  name: string;
  inputName: string;
}

export const CustomerSearch = (props: React.PropsWithChildren<ICustomerSearchProps>) => {
  const { } = props;

  const [alertMessage, setAlertMessage] = React.useState({
    isError: false,
    errorMess: ''
  });

  const [stateKeyboard, setStateKeyboard] = React.useState<IKeyboard>({
    layoutName: 'default',
    phoneorcode: '',
    name: '',
    inputName: 'phoneorcode'
  } as IKeyboard);
  const [options, setOptions] = React.useState<ICustomer[]>([] as ICustomer[]);
  const { ref, isVisible, setIsVisible } = useVisible(true);
  const [, setcurrentCustomer] = useRecoilState(currentCustomerState);
  const [currentUser] = useRecoilState(currentUserState);

  const [loading, setLoading] = React.useState(false);
  const existingToken = getStoredAccessToken();
  const history = useHistory();
  const keyboard = React.useRef<KeyboardReact>();
  const commonOptions = {
    layout: {
      shift: [
        '1 2 3 4 5 6 7 8 9 0 - + = {bksp}',
        'q w e r t y u i o p {clear}',
        'a s d f g h j k l : {enter}',
        'z x c v b n m , . ? {shift}',
        '@ {space}'
      ],
      default: [
        '1 2 3 4 5 6 7 8 9 0 - + = {bksp}',
        'Q W E R T Y U I O P {clear}',
        'A S D F G H J K L :',
        'Z X C V B N M , . ? {shift}',
        '@ {space}'
      ]
    },
    buttonTheme: [{
      class: 'btnKeyboard',
      buttons: '1 2 3 4 5 6 7 8 9 0 - + = {bksp} q w e r t y u i o p {clear} a s d f g h j k l : z x c v b n m , . ? {shift} .com @ {space} '
    },
    {
      class: 'btnKeyboard',
      buttons: '1 2 3 4 5 6 7 8 9 0 - + = {bksp} Q W E R T Y U I O P {clear} A S D F G H J K L : Z X C V B N M , . ? {shift} .COM @ {space} '
    }
    ]
    ,
    layoutName: stateKeyboard?.layoutName,
    theme: 'hg-theme-default hg-theme-numeric',
    mergeDisplay: true,
    display: {
      '{bksp}': 'Xóa',
      '{space}': 'Cách',
      '{clear}': 'Xóa tất cả',
      '{shift}': 'Hoa/thường'
    },
    syncInstanceInputs: true
  };

  const onClickVisible = () => {
    setIsVisible(true);
  };

  const getCustomer = async (value) => {
    if (value) {
      await axios.get(
        `${API_URL}/customers?keyword=` + value,
        {
          headers: {
            authorization: `Bearer ${existingToken}`
          }
        }
      ).then(e => {
        if (e.data.data.length > 0) {
          setOptions(e.data.data as ICustomer[]);
          setAlertMessage({
            isError: false,
            errorMess: 'Không tìm thấy khách hàng!'
          });
        } else {
          setOptions(e.data.data as ICustomer[]);
          setAlertMessage({
            isError: true,
            errorMess: 'Không tìm thấy khách hàng!'
          });
        }
      });
    }
  };

  const onKeyPressKeyBoard = (button, keyboardRef: MutableRefObject<Keyboard | undefined>) => {
    if (button === '{clear}') {
      keyboardRef.current?.clearInput(stateKeyboard?.inputName);
      if (stateKeyboard?.inputName === 'phoneorcode') {
        setStateKeyboard({ ...stateKeyboard, phoneorcode: '' } as IKeyboard);
      }
      else {
        setStateKeyboard({ ...stateKeyboard, name: '' } as IKeyboard);
      }
      return;
    }
    if (button === '{shift}') {
      const layoutName = stateKeyboard?.layoutName === 'default' ? 'shift' : 'default';
      setStateKeyboard({ ...stateKeyboard, layoutName: layoutName } as IKeyboard);
      return;
    }
  };

  const comeBack = () => {
    history.push('/casher');
  };

  const setActiveInput = (event) => {
    setStateKeyboard({
      ...stateKeyboard,
      inputName: event.target.id
    } as IKeyboard);
  };

  const onChangeAllKeyboard = async (input: IKeyboard) => {
    setStateKeyboard({
      ...stateKeyboard,
      inputName: input.inputName
    } as IKeyboard);

    if (stateKeyboard?.inputName === 'phoneorcode') {
      keyboard.current?.setInput(VietKey(input.phoneorcode));
      setStateKeyboard({
        ...stateKeyboard,
        phoneorcode: input.phoneorcode ? VietKey(input.phoneorcode) : ''
      } as IKeyboard);
      await getCustomer(stateKeyboard.phoneorcode);
    }
    if (stateKeyboard?.inputName === 'name') {
      keyboard.current?.setInput(VietKey(input.name));
      setStateKeyboard({
        ...stateKeyboard,
        name: input.name ? VietKey(input.name) : ''
      } as IKeyboard);
    }
  };

  const onCreateCustomer = async () => {
    setLoading(true);
    if (stateKeyboard?.phoneorcode && stateKeyboard.name && stateKeyboard?.phoneorcode.length > 9) {
      const data = {
        name: stateKeyboard.name,
        mobile: stateKeyboard?.phoneorcode,
        salonBranchId: currentUser?.salonBranchCurrentId,
        salonId: currentUser?.salonId
      };
      await axios.post(`${API_URL}/Customers`, data, {
        headers: {
          authorization: `Bearer ${existingToken}`
        }
      }).then(e => {
        setcurrentCustomer({
          id: e.data.data.id,
          mobile: e.data.data.mobile,
          code: e.data.data.code,
          name: e.data.data.name,
          refByCustomerId: e.data.data.refByCustomerId,
          salonBranchId: e.data.data.salonBranchId,
          salonId: e.data.data.salonId,
          status: e.data.data.status
        } as ICustomer);
        setLoading(false);
        history.push('/casher');
      }).catch(() => {
        setAlertMessage({
          isError: true,
          errorMess: 'Khách hàng đã tồn tại'
        });
        setLoading(false);
      });
    }
    else {
      setAlertMessage({
        isError: true,
        errorMess: 'Vui lòng nhập đầy đủ thông tin'
      });
      setLoading(false);
    }
  };

  return (
    <_CustomerSearch>
      <div className="container">
        <div className="cover">
          <div className="flex-form">
            <button
              className='btn-comeBack'
              style={{ borderRadius: '10px 0px 0px 10px' }}
              type='button'
            >
              <span style={{ padding: 30, fontSize: 30 }} />
            </button>
            <input
              autoComplete='off'
              type="text"
              onFocus={setActiveInput}
              id="phoneorcode"
              name='phoneorcode'
              value={stateKeyboard?.phoneorcode ? stateKeyboard?.phoneorcode : ''}
              onClick={onClickVisible}
              onChange={async (e) => {
                keyboard.current?.setInput(e.target.value);
                setStateKeyboard({
                  ...stateKeyboard,
                  phoneorcode: e.target.value,
                  inputName: e.target.id
                } as IKeyboard);
                if (!e.target.value) {
                  setOptions([]);
                } else {
                  await getCustomer(stateKeyboard.phoneorcode);
                }
              }}
              placeholder="Nhập theo số điện thoại hoặc mã khách hàng"
            />
            <button
              className='btn-phone'
              style={{ borderRadius: '0px 10px 10px 0px' }}
              type="button"
              onClick={comeBack}
            >
              <ArrowRightOutlined style={{ padding: 15, fontSize: 30 }} />
            </button>
            {options.length > 0 &&
              <div
                className='searchs'
                style={{ display: isVisible ? 'block' : 'none' }}
              >
                {
                  stateKeyboard?.phoneorcode && stateKeyboard?.inputName === 'phoneorcode' ?
                    options.map(function (item, i) {
                      return (
                        <li className='search'
                          key={i}
                          onClick={() => {
                            setIsVisible(true);
                            setcurrentCustomer({
                              id: item.id,
                              mobile: item.mobile,
                              code: item.code,
                              name: item.name,
                              refByCustomerId: item.refByCustomerId,
                              salonBranchId: item.salonBranchId,
                              salonId: item.salonId,
                              status: item.status
                            } as ICustomer);
                            history.push('/casher');
                          }}
                        >
                          <span>
                            {item.code}-{item.name}
                          </span>

                        </li>
                      );
                    }) : ''
                }
              </div>
            }

          </div>
          <div className="flex-form">
            <button
              className='btn-name'
              style={{ borderRadius: '10px 0px 0px 10px' }}
              type='button'
            >
              <span style={{ padding: 30, fontSize: 30 }} />
            </button>
            <input
              autoComplete='off'
              type="text"
              onFocus={setActiveInput}
              id="name"
              name='name'
              value={stateKeyboard?.name ? stateKeyboard?.name : ''}
              onClick={onClickVisible}
              onChange={(e) => {
                keyboard.current?.setInput(e.target.value);
                setStateKeyboard({
                  ...stateKeyboard,
                  name: e.target.value,
                  inputName: e.target.id
                } as IKeyboard);

              }}
              placeholder="Nhập tên khách hàng"
            />
            <Button
              className='btn-name-right'
              style={{ borderRadius: '0px 10px 10px 0px' }}
            >
              <span style={{ padding: 30, fontSize: 30 }} />
            </Button>
          </div>
          <div style={{ width: '100%', display: 'flex' }}>

            <div className="flex-btn-create">
              <Button
                onClick={onCreateCustomer}
                className='btn-Create'
                style={{ borderRadius: '10px 10px 10px 10px' }}
                loading={loading}
              >
                TẠO
              </Button>
            </div>
            <div className="flex-btn-ignore">
              <button
                className='btn-ignore'
                onClick={comeBack}
                style={{ borderRadius: '10px 10px 10px 10px' }}
                type='button'
              >
                BỎ QUA
                </button>
            </div>
          </div>
          {
            alertMessage.isError && (
              <Alert style={{ fontSize: 20, marginTop: 10 }}
                message={alertMessage.errorMess} type="error" />
            )
          }
        </div>
        <section
          className='Keyboard'
          ref={ref}
          style={{ display: isVisible ? 'block' : 'none' }}
        >
          <KeyboardWrapper
            inputName={stateKeyboard?.inputName}
            commonOptions={commonOptions}
            keyboardRef={keyboard}
            onKeyPress={onKeyPressKeyBoard}
            onChangeAll={onChangeAllKeyboard}
          />
        </section>
      </div>
    </_CustomerSearch >
  );
};