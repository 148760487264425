
import React from 'react';

export const useVisible = (initialIsVisible) => {
  const [isVisible, setIsVisible] = React.useState(initialIsVisible);
  const ref = React.useRef<any>();
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      return setIsVisible(false);
    }
    return setIsVisible(true);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [initialIsVisible]);

  return { ref, isVisible, setIsVisible };
};