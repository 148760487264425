import { ApplicationPage, i18n } from 'libs/framework';
import { isAuthenticated } from 'libs/framework/policies';
import React from 'react';

import { DashboardLayout } from '@/dashboard/layouts';

import { invoiceDiscountPath } from './PageInvoiceDiscount.shared';
import { InvoiceDiscount } from './views/InvoiceDiscount';

export class PageInvoiceDiscount extends ApplicationPage {
  public static routeInfo = {
    title: i18n('Giảm giá hoá đơn'),
    path: invoiceDiscountPath,
    policies: [isAuthenticated]
  }

  public render() {
    return (
      <DashboardLayout>
        <InvoiceDiscount />
      </DashboardLayout>
    );
  }
}